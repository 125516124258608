import { defineMessages } from 'react-intl'

export default defineMessages({
  work: {
    id: 'Footer.work',
    defaultMessage: 'Work at Lifesum',
  },
  careers: {
    id: 'Footer.careers',
    defaultMessage: 'Careers',
  },
  connect: {
    id: 'Footer.connect',
    defaultMessage: 'Connect',
  },
  blog: {
    id: 'Footer.blog',
    defaultMessage: 'Blog',
  },
  faq: {
    id: 'Footer.faq',
    defaultMessage: 'FAQ',
  },
  contact: {
    id: 'Footer.contact',
    defaultMessage: 'Contact',
  },
  help: {
    id: 'Footer.help',
    defaultMessage: 'Help',
  },
  learnMore: {
    id: 'Footer.learnMore',
    defaultMessage: 'Learn more',
  },
  aboutLifesum: {
    id: 'Footer.aboutLifesum',
    defaultMessage: 'About Lifesum',
  },
  press: {
    id: 'Footer.press',
    defaultMessage: 'Press',
  },
  healthEdu: {
    id: 'Footer.healthEdu',
    defaultMessage: 'Health education',
  },
  legal: {
    id: 'Footer.legal',
    defaultMessage: 'Legal',
  },
  TOSandPrivacyPolicy: {
    id: 'Footer.TOSandPrivacyPolicy',
    defaultMessage: 'Terms of Service & Privacy Policy',
  },
  allRightsReserved: {
    id: 'Footer.allRightsReserved',
    defaultMessage: 'All rights reserved',
  },
  foot_how_we_use_cookies: {
    id: 'foot_how_we_use_cookies',
    defaultMessage: 'How we use cookies',
  },
})
