import { useEffect, useState } from 'react'
import { SM, MD, LG, XL, XXL } from '../breakpoints'
import { isSSR } from 'js/isSSR'

let mqSm, mqMd, mqLg, mqXl, mqXxl
if (!isSSR) {
  mqSm = matchMedia(`(min-width: ${SM}px)`)
  mqMd = matchMedia(`(min-width: ${MD}px)`)
  mqLg = matchMedia(`(min-width: ${LG}px)`)
  mqXl = matchMedia(`(min-width: ${XL}px)`)
  mqXxl = matchMedia(`(min-width: ${XXL}px)`)
}

const useBreakpoints = (defaultBreakpoint = 'SM') => {
  const [queries, setQueries] = useState({
    SM: isSSR ? defaultBreakpoint === 'SM' : mqSm.matches,
    MD: isSSR ? defaultBreakpoint === 'MD' : mqMd.matches,
    LG: isSSR ? defaultBreakpoint === 'LG' : mqLg.matches,
    XL: isSSR ? defaultBreakpoint === 'XL' : mqXl.matches,
    XXL: isSSR ? defaultBreakpoint === 'XXL' : mqXxl.matches,
  })

  const handleChange = () => {
    setQueries({
      SM: mqSm.matches,
      MD: mqMd.matches,
      LG: mqLg.matches,
      XL: mqXl.matches,
      XXL: mqXxl.matches,
    })
  }

  useEffect(() => {
    mqSm.addListener(handleChange)
    mqMd.addListener(handleChange)
    mqLg.addListener(handleChange)
    mqXl.addListener(handleChange)
    mqXxl.addListener(handleChange)

    return () => {
      mqSm.removeListener(handleChange)
      mqMd.removeListener(handleChange)
      mqLg.removeListener(handleChange)
      mqXl.removeListener(handleChange)
      mqXxl.removeListener(handleChange)
    }
  }, [])

  return queries
}

export default useBreakpoints
