import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../components/Logo'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { trackViewFaq } from '../../analytics'
import { useInView } from 'react-intersection-observer'
import useLocale from '../../hooks/useLocale'
import loadable from '@loadable/component'
import Gift from 'media/gift.svg?svgr-webpack'
import { GetPolicyLink } from '../../utils/getPolicyLink'
import CookieConsent from '../CookieConsent/CookieConsent'
import CompanyImprint from 'js/components/CompanyImprint/CompanyImprint'

const AppButtons = loadable(() => import('../AppButtons/AppButtons'))

const Heading = (props) => <h3 className="type-upper-fat mb-8" {...props} />

const ListItem = ({ link = false, href, ...props }) => {
  const className = 'text-grey-darkest no-underline transition hover:text-blue'

  return (
    <li className="mb-8">
      {link ? (
        <Link className={className} to={href} {...props} />
      ) : (
        <a className={className} href={href} {...props} />
      )}
    </li>
  )
}

ListItem.propTypes = {
  link: PropTypes.bool,
  href: PropTypes.string,
}

function Footer(props) {
  const [inViewRef, inView] = useInView({ threshold: 0.8, triggerOnce: true })
  const [showCookieConsent, setShowCookieConsent] = useState(false)
  const locale = useLocale()
  const {
    appButtons = true,
    flat,
    marginless,
    roomForContent,
    showCareers = true,
    beta,
  } = props
  return (
    <>
      <footer
        ref={inViewRef}
        className={cn('relative text-grey-darkest', {
          'mt-64 md:mt-96 lg:mt-128 xl:mt-192': !(marginless || flat),
        })}
      >
        <div
          className={cn('pb-16 pt-48', {
            'lg:pt-128': marginless || roomForContent === 'small',
            'md:pt-192': roomForContent === true,
            'bg-white': !beta,
            'bg-BgMain': beta,
          })}
        >
          <div className="max-w-1680 px-16 md:px-40 lg:px-64 xxl:px-128">
            <Link to="/" className="mb-24 inline-block md:mb-40">
              <span className="sr-only">Lifesum.com start page</span>
              <Logo />
            </Link>
            <div className="-mx-12 flex flex-wrap leading-body lg:-mx-20">
              <h2 className="sr-only">Assorted Lifesum links</h2>
              <div className="flex w-1/2 grow flex-col px-12 md:w-1/3 lg:w-1/4 lg:px-20 xl:w-5/24">
                {showCareers && (
                  <Fragment>
                    <Heading>
                      <FormattedMessage {...messages.work} />
                    </Heading>
                    <ul className="mb-24 md:mb-40">
                      <ListItem href="http://jobs.lifesum.com">
                        <FormattedMessage {...messages.careers} />
                      </ListItem>
                    </ul>
                  </Fragment>
                )}
                <Heading>
                  <FormattedMessage {...messages.connect} />
                </Heading>
                <ul className="mb-24 md:mb-40">
                  <ListItem href="https://instagram.com/lifesum/">
                    Instagram
                  </ListItem>
                  <ListItem href="https://www.youtube.com/channel/UCy64gY3TCURHxigExH0cHIg">
                    YouTube
                  </ListItem>
                  <ListItem href="https://www.pinterest.com/Lifesum/">
                    Pinterest
                  </ListItem>
                  <ListItem href="https://www.facebook.com/Lifesum">
                    Facebook
                  </ListItem>
                  <ListItem href="https://twitter.com/lifesum">
                    Twitter
                  </ListItem>
                </ul>
              </div>
              <div className="flex w-1/2 grow flex-col px-12 md:w-1/3 lg:w-1/4 lg:px-20 xl:w-5/24">
                <Heading>
                  <FormattedMessage {...messages.help} />
                </Heading>
                <ul className="mb-24 md:mb-40">
                  <ListItem
                    href="https://lifesum.helpshift.com/"
                    onClick={() => trackViewFaq()}
                  >
                    <FormattedMessage {...messages.faq} />
                  </ListItem>
                  <ListItem href="/contact/" link>
                    <FormattedMessage {...messages.contact} />
                  </ListItem>
                </ul>
                <Heading>
                  <FormattedMessage {...messages.learnMore} />
                </Heading>
                <ul className="mb-24 md:mb-40">
                  <ListItem href="/press/" link>
                    <FormattedMessage {...messages.press} />
                  </ListItem>
                  <ListItem link href="/nutrition-explained/">
                    <FormattedMessage
                      id="Header.menuBar.articles"
                      defaultMessage="Articles"
                    />
                  </ListItem>
                  <ListItem href="/work/" link>
                    Lifesum for Work
                  </ListItem>
                </ul>
                <Link to="/gift-card/" className="mb-40">
                  <span className="text-Type transition hover:text-blue-dark">
                    <Gift id="gift" className="mr-6 fill-current" />
                    <FormattedMessage
                      id="Header.menuBar.giftCard"
                      defaultMessage="Gift a gift"
                    />
                  </span>
                </Link>
              </div>
              <div className="flex w-1/2 grow flex-col px-12 md:w-1/3 lg:w-1/4 lg:px-20 xl:w-5/24">
                <Heading>
                  <FormattedMessage {...messages.legal} />
                </Heading>
                <ul className="mb-24 md:mb-40">
                  <ListItem
                    link
                    href={GetPolicyLink(locale.country, locale.language)}
                  >
                    <FormattedMessage {...messages.TOSandPrivacyPolicy} />
                  </ListItem>
                  <ListItem link href="/policy-hiring">
                    <FormattedMessage
                      id="Footer.policyHiring"
                      defaultMessage="Privacy Policy — Employees, consultants and job applicants"
                    />
                  </ListItem>
                  <ListItem link href="/policy-contact">
                    <FormattedMessage
                      id="Footer.policyBusinessWeb"
                      defaultMessage="Privacy Policy — Business contacts and website visitors"
                    />
                  </ListItem>
                  <li className="mb-8">
                    <button
                      className={cn(
                        'text-left leading-default transition',
                        'text-grey-darkest hover:text-blue',
                      )}
                      onClick={() => setShowCookieConsent(true)}
                    >
                      <FormattedMessage
                        id="cookies_update_prefs"
                        defaultMessage="Update cookie preferences"
                      />
                    </button>
                  </li>
                </ul>
                <small className="text-grey type-14">
                  <FormattedMessage {...messages.allRightsReserved} />{' '}
                  {new Date().getFullYear()} Lifesum AB
                </small>
                <small className="text-grey type-14">
                  <CompanyImprint />
                </small>
                {props.children}
              </div>
              {Boolean(appButtons) && (
                <div className="flex w-1/2 grow flex-col px-12 md:w-1/3 lg:w-1/4 lg:px-20 xl:w-9/24">
                  <div className="mb-40 text-left md:text-center lg:text-right">
                    {inView && <AppButtons />}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </footer>
      {showCookieConsent && (
        <CookieConsent
          shouldLoadSettings
          onClose={() => setShowCookieConsent(false)}
        />
      )}
    </>
  )
}

Footer.propTypes = {
  appButtons: PropTypes.bool,
  marginless: PropTypes.bool,
  showCareers: PropTypes.bool,
  roomForContent: PropTypes.oneOf([true, false, 'small']),
  flat: PropTypes.bool,
  children: PropTypes.node,
  beta: PropTypes.bool,
}

export default Footer
