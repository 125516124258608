import React from 'react'

const DisabledCookies = () => (
  <div className="c-message c-message--warning">
    <p>
      You have cookies/web storage disabled. In order for this site to function
      properly, cookies are required.
    </p>
  </div>
)

export default DisabledCookies
