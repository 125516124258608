import React from 'react'
import cn from 'classnames'

import LogoIcon from 'media/logo.svg?svgr-webpack'

type Props = {
  inverted?: boolean
  className?: string
  style?: {}
}

const Logo = ({ inverted, className, style }: Props) => (
  <LogoIcon
    className={cn('c-logo', { 'c-logo--inverted': inverted }, className)}
    style={style}
  />
)

export default Logo
