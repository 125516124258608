import React, { useEffect, useState } from 'react'
import useLocale from 'js/hooks/useLocale'
import { useLocation } from 'react-router-dom'

export default function CompanyImprint() {
  const { country } = useLocale()
  const location = useLocation()
  const [shouldShow, setShouldShow] = useState(false)
  useEffect(() => {
    if (country === 'DE' || location.pathname.match(/^\/de\//i)) {
      setShouldShow(true)
    }
  }, [country, location.pathname])

  if (!shouldShow) return null

  return (
    <>
      <p className="mb-4 mt-16 type-12">Impressum</p>
      <p className="mb-4 mt-16 type-12">
        Lifesum AB Repslagargatan 17B, 118 46 Stockholm Schweden
      </p>
      <p className="mb-16 type-12">
        <a
          className="text-grey hover:text-grey-darker"
          href="https://lifesum.com/contact"
        >
          https://lifesum.com/contact
        </a>
      </p>
      <p className="mb-16 mt-8 type-12">
        Inhaltlich verantwortlich: Gustav Blomster <br />
        Registernummer: 556729-2841 <br />
        Umsatzsteueridentifikationsnummer: SE556729284101 <br />
        Telefon: +46 (0)8-55920552 <br />
        E-Mail: contact@lifesum.com
      </p>
    </>
  )
}
