/* global dataLayer */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DisabledCookies from './DisabledCookies'
import { isSSR } from 'js/isSSR'
import loadable from '@loadable/component'
import { useLocation } from 'react-router-dom'

const LoginOverlay = loadable(() => import('./Login/LoginOverlay'))

const InstallBanner = loadable(() =>
  import(/* webpackChunkName: "InstallBanner" */ './InstallBanner'),
)

function DefaultLayout({ dark, showInstallBanner = true, children }) {
  const location = useLocation()
  const isMarketingPage = location.pathname.indexOf('/news/') > -1
  const showCookieNotice = !isSSR && !navigator.cookieEnabled
  const [showLoginOverlay, setShowLoginOverlay] = useState(false)

  useEffect(() => {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({ event: 'optimize.activate' })
    }

    if (
      document.location.search.indexOf('login=1') > -1 ||
      document.location.search.indexOf('reset=1') > -1
    ) {
      setTimeout(() => {
        setShowLoginOverlay(true)
      }, 500)
    }
  }, [])

  return (
    <div className={dark ? 'bg-dark-mode-bg text-dark-mode-text' : 'bg-BgMain'}>
      {!isSSR && showInstallBanner && (
        <InstallBanner install={isMarketingPage} />
      )}
      {showCookieNotice && <DisabledCookies />}
      {showLoginOverlay && (
        <LoginOverlay
          onClose={() => {
            setShowLoginOverlay(false)
          }}
        />
      )}
      <div>{children}</div>
    </div>
  )
}

DefaultLayout.propTypes = {
  dark: PropTypes.bool,
  showInstallBanner: PropTypes.bool,
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool,
}

export default DefaultLayout
